<template>
    <div class="EncounterMgmt">
        <div class="EncounterMgmt-head">
            <span class="iconfont icon-fanhuijiantou" @click="goHistotry()"></span>
        </div>
        <div class="common">
            <div class="common-container">
                <div class="EncounterMgmt-info">
                    <h5>张三</h5>
                    <div class="EncounterMgmt-xiaoxi">
                        <p>
                            <span>男</span>
                            <span>55岁</span>
                        </p>
                        <p>
                            <span>1956-08-10</span>
                            <span>18355991437</span>
                        </p>
                    </div>
                </div>
                <div class="EncounterMgmt-bottom">
                    <div class="EncounterMgmt-moren">
                        <div class="img">
                            <img src="../assets/images/u905_selected.svg" alt="">
                        </div>
                        <div >默认就诊人</div>
                    </div>
                    <div class="btn">
                        <button>修改</button>
                        <button>删除</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="addbtn">
            <button @click="goEncounterMgmt()">添加就诊人</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EncounterMgmt',
    methods: {
        goHistotry() {
            this.$router.go(-1);
        },
        goEncounterMgmt() {
            this.$router.push('/addCustomer');
        }
    },
}
</script>

<style lang="less" scoped>
@import '../assets/css/EncounterMgmt.less';
</style>